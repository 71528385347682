<template>
	<div>
		<br />
		<div class="col-sm-12 text-right">
			<h1>Roles</h1>
		</div>
		<br />

		<DataGrid :config="dataGridConfig" :data="roles" :select="seleccionar" @actions="dataGridActions">
			<div class="col-sm-2">
				<button class="btn warning-btn" @click="obtener_roles">Recargar</button>
			</div>
			<div class="col-sm-2">
				<button v-if="$auth.can('account','crear_roles')" class="btn principal-btn" @click="cargar_modal(1)">Nuevo</button>
			</div>
			<div class="col-sm-2">
				<button v-if="$auth.can('account','editar_roles')" class="btn secondary-btn" @click="cargar_modal(2)">Editar</button>
			</div>
			<div class="col-sm-2">
				<button v-if="$auth.can('account','eliminar_roles')" class="btn danger-btn" @click="pre_eliminar">Eliminar</button>
			</div>
		</DataGrid>

		<Modal v-if="eliminar" @close="cancelar_eliminar" :options="{width: '30vw', type:'error'}" class="bpb-modal">
			<h2 class="title">Eliminar organizacion(es)</h2>
			<div class="body">
				<p class="text-center">¿Realmente eliminar el rol "{{ rol.nombre }}"?</p>
			</div>
			<div class="footer">
				<div class="d-flex justify-content-end">
					<div class="col-sm-3">
						<button class="btn principal-btn" @click="eliminar_rol">Eliminar</button>
					</div>
					<div class="col-sm-3">
						<button class="btn complementary-btn" @click="cancelar_eliminar">Cancelar</button>
					</div>
				</div>
			</div>
		</Modal>

		<Modal v-if="modal != 0" @close="cancelar_modal" class="bpb-modal">
			<h2 class="title">Rol</h2>
			<div class="body">
				<div class="row form-group">
					<label for="nombre" class="col-form-label col-sm-2">Nombre</label>
					<div class="col-sm-10"><input v-model="rol.nombre" type="text" name="nombre" id="nombre" class="form-control" autocomplete="off"></div>
				</div>
				<div class="row form-group">
					<label for="Descripción" class="col-form-label col-sm-2">Descripción</label>
					<div class="col-sm-10"><input v-model="rol.descripcion" type="text" name="Descripción" id="Descripción" class="form-control" autocomplete="off"></div>
				</div>
				<div class="row form-group">
					<label for="tipo" class="col-form-label col-sm-2">Tipo</label>
					<div v-if="modal == 1" class="col-sm-10">
						<select v-model="rol.tipo" name="tipo" id="tipo" class="form-control" @change="limpiar_rol_items">
							<option value="1">Rol</option>
							<option value="2">Grupo</option>
						</select>
					</div>
					<div v-else class="col-sm-10">{{ rol.tipo == 1 ? 'Rol': 'Grupo' }}</div>
				</div>
				<div v-if="rol.tipo == 1" class="row form-group">
					<label for="tipo" class="col-form-label col-sm-2">Permisos</label>
					<div class="col-sm-10">
						<div class="container-fluid">
							<div class="row list-header">
								<div class="col-sm-2">Aplicación</div>
								<div class="col-sm-5">Permiso</div>
								<div class="col-sm-1">Acceso</div>
								<div class="col-sm-1">Alcance</div>
								<div class="col-sm-2">Ver app</div>
							</div>
							<div v-for="tmpPermiso in items.permisos.seleccionados" class="row list-item">
								<div class="col-sm-2">{{ tmpPermiso.aplicacion_nombre }}</div>
								<div class="col-sm-5">{{ tmpPermiso.permiso_descripcion }}</div>
								<div class="col-sm-1">{{ activo_inactivo(tmpPermiso.acceso) }}</div>
								<div class="col-sm-1">{{ activo_inactivo(tmpPermiso.alcance) }}</div>
								<div class="col-sm-2">{{ activo_inactivo(tmpPermiso.ver_aplicacion) }}</div>
								<div class="col-sm-1 quit" title="Quitar permiso">
									<img src="https://static.bienparabien.com/constelacion/img/bin.png" alt="Quitar" @click="quitar_permiso(tmpPermiso.permiso_id)" title="Quitar permiso">
								</div>
							</div>
						</div>
					</div>
				</div>
				<div v-else class="row form-group">
					<label for="tipo" class="col-form-label col-sm-2">Roles</label>
					<div class="col-sm-10">
						<div class="container-fluid">
							<div class="row list-header">
								<div class="col-sm-10 text-center">Rol</div>
							</div>
							<div v-for="tmpRol in items.roles.seleccionados" class="row list-item">
								<div class="col-sm-10 text-center">{{ tmpRol.nombre }}</div>
								<div class="col-sm-2 text-center quit" @click="quitar_rol(tmpRol.id)">
									<img src="https://static.bienparabien.com/constelacion/img/bin.png" alt="Quitar" @click="quitar_rol(tmpRol.id)" title="Quitar rol">
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="footer">
				<div class="row">
					<div class="col-sm-12 d-flex justify-content-end">
						<div class="col-sm-3">
							<button v-if="rol.tipo == 1" class="btn principal-btn" @click="agregar_nuevo_permiso">Agregar permiso</button>
							<button v-else class="btn principal-btn" @click="agregar_rol">Agregar rol</button>
						</div>
						<div class="col-sm-3">
							<button class="btn secondary-btn" @click="guardar_modal">Guardar</button>
						</div>
						<div class="col-sm-3">
							<button class="btn complementary-btn" @click="cancelar_modal">Cancelar</button>
						</div>
					</div>
				</div>
			</div>
		</Modal>

		<Modal v-if="modalPermiso" @close="cancelar_permiso" class="bpb-modal">
			<h2 class="title">Agregar permiso</h2>
			<div class="body">
				<div class="row form-group">
					<label for="aplicaciones" class="col-form-label col-sm-2">Aplicaciones</label>
					<div class="col-sm-10">
						<select v-model="nuevoPermiso.aplicacion_id" name="aplicaciones" id="aplicaciones" class="form-control" @change="obtener_permisos">
							<option v-for="tmpAplicacion in aplicaciones" :key="tmpAplicacion.id" :value="tmpAplicacion.id">{{ tmpAplicacion.nombre }}</option>
						</select>
					</div>
				</div>
				<div class="row form-group">
					<label for="permisos" class="col-form-label col-sm-2">Permisos</label>
					<div class="col-sm-10">
						<select v-model="nuevoPermiso.permiso_id" name="permisos" id="permisos" class="form-control">
							<option v-for="tmpPermiso in permisos_validos" :key="tmpPermiso.id" :value="tmpPermiso.id">{{ tmpPermiso.descripcion }}</option>
						</select>
					</div>
				</div>
				<div class="row form-group">
					<label for="acceso" class="col-form-label col-sm-2">Acceso</label>
					<div class="col-sm-10">
						<select v-model="nuevoPermiso.acceso" name="acceso" id="acceso" class="form-control">
							<option value="1">Permitido</option>
							<option value="0">Denegado</option>
						</select>
					</div>
				</div>
				<div class="row form-group">
					<label for="alcance" class="col-form-label col-sm-2">Alcance</label>
					<div class="col-sm-10">
						<select v-model="nuevoPermiso.alcance" name="alcance" id="alcance" class="form-control">
							<option value="1">Todos</option>
							<option value="2">Propietario</option>
							<option value="3">Otro</option>
						</select>
					</div>
				</div>
				<div class="row form-group">
					<label for="ver_aplicacion" class="col-form-label col-sm-2" title="Define si la aplicación a la que pertence el permiso es visible en Constelación o no">Visibilidad(?)</label>
					<div class="col-sm-10">
						<select v-model="nuevoPermiso.ver_aplicacion" name="ver_aplicacion" id="ver_aplicacion" class="form-control">
							<option value="1">Visible</option>
							<option value="0">Oculto</option>
						</select>
					</div>
				</div>
			</div>
			<div class="footer">
				<div class="row">
					<div class="col-sm-12 text-right">
						<button class="btn btn-primary mr-2" @click="guardar_permiso">Agregar</button>
						<button class="btn btn-danger" @click="cancelar_permiso">Cancelar</button>
					</div>
				</div>
			</div>
		</Modal>

		<Modal v-if="modalRol" @close="cancelar_rol" class="bpb-modal">
			<h2 class="title">Agregar Rol</h2>
			<div class="body">
				<div class="row form-group">
					<label for="rol" class="col-form-label col-sm-2">Roles</label>
					<div class="col-sm-10">
						<select v-model="nuevoRol.id" name="rol" id="rol" class="form-control">
							<option v-for="tmpRol in roles_validos" :key="tmpRol.id" :value="tmpRol.id">{{ tmpRol.nombre }}</option>
						</select>
					</div>
				</div>
			</div>
			<div class="footer">
				<div class="row">
					<div class="col-sm-12 d-flex justify-content-end">
						<div class="col-sm-3">
							<button class="btn principal-btn" @click="guardar_rol">Agregar</button>
						</div>
						<div class="col-sm-3">
							<button class="btn complementary-btn" @click="cancelar_rol">Cancelar</button>
						</div>
					</div>
				</div>
			</div>
		</Modal>
	</div>
</template>

<script type="text/javascript">
	import DataGrid from '@/components/DataGrid'
	import Modal from '@/components/Modal'

	import api from '@/apps/account/api/account'

	export default {
		components: {
			DataGrid, Modal
		}
		,data: function() {
			return {
				dataGridConfig: {
					name: 'roles'
					,cols: {
						id: 'ID'
						,nombre: 'Nombre'
						,descripcion: 'Descripción'
						,tipo: 'Tipo'
					}
					,filters: {
						cols: {
							id: 'ID'
							,nombre: 'Nombre'
							,descripcion: 'Descripción'
							,tipo: {
								text: 'Estatus'
								,options: {
									1: 'Rol'
									,2: 'Grupo'
								}
							}
							,created_at: 'Fecha de creación'
						}
					}
					,paginator: {
						pagina_actual: 1
						,total_registros: 1
						,registros_por_pagina: 20
					}
					,mutators: {
						tipo: function(val) {
							return val == 1 ? 'Rol' : 'Grupo';
						}
					}
				}
				,options: {
					page: 1
					,order_col: 'id'
					,order_dir: 'desc'
					,limit: 20
					,filters: []
				}
				,roles: []
				,rol: {
					nombre: null
					,descripcion: null
					,tipo: 1
				}
				,modal: 0
				,seleccionar: false
				,seleccion: []
				,eliminar: false
				,items: {
					permisos: {
						originales: []
						,seleccionados: []
					}
					,roles: {
						originales: []
						,seleccionados: []
					}
				}
				,aplicaciones: []
				,permisos: []
				,nuevoPermiso: {
					aplicacion_id: 0
					,permiso_id: 0
					,alcance: 1
					,acceso: 1
					,ver_aplicacion: 1
					,aplicacion_nombre: null
					,permiso_descripcion: null
				}
				,modalPermiso: false
				,modalRol: false
				,nuevoRol: {
					id: null
					,nombre: null
				}
			}
		}
		,mounted: function() {
			this.obtener_roles();
		}
		,methods: {
			obtener_roles: async function() {
				let response = (await api.listar_roles(this.options)).data;

				this.roles = response.data;

				this.dataGridConfig.paginator.pagina_actual = response.current_page;
				this.dataGridConfig.paginator.total_registros = response.total;
				this.dataGridConfig.paginator.registros_por_pagina = parseInt(response.per_page);
			}
			,dataGridActions: function(tipo, valor) {
				if (tipo == 'options') {
					this.options = valor;
					this.obtener_roles();
				}else {
					this.seleccion = valor;
					this.seleccionar = true;
				}
			}
			,pre_eliminar: function() {
				if (this.seleccion.length > 0) {
					this.rol = this.seleccion[0];
					this.eliminar = true;
				}else
					this.$helper.showMessage('Error','Tienes que seleccionar un rol a eliminar', 'error', 'alert');
			}
			,cancelar_eliminar: function() {
				this.rol = {
					nombre: null
					,descripcion: null
					,tipo: 1
				};

				this.eliminar = false;
				this.seleccionar = false;
			}
			,eliminar_rol: async function() {
				await api.eliminar_roles(this.rol.id);
				this.cancelar_eliminar();
				this.obtener_roles();
			}

			//Nuevo rol
			,cancelar_modal: function() {
				this.rol = {
					nombre: null
					,descripcion: null
					,tipo: 1
				};
				this.items = {
					permisos: {
						originales: []
						,seleccionados: []
						,eliminados: []
					}
					,roles: {
						originales: []
						,seleccionados: []
						,eliminados: []
					}
				}
				this.modal = 0;
				this.seleccionar = false;
			}
			,cargar_modal: async function(tipo) {
				if (tipo == 1)
					this.modal = 1;
				else {
					if (this.seleccion.length > 0) {
						this.rol = this.seleccion[0];

						if (this.rol.tipo == 1) {
							this.items.permisos.originales = (await api.listar_permisos(this.rol.id)).data;

							for(let i=0; i<this.items.permisos.originales.length; i++) {
								this.items.permisos.originales[i].permiso_descripcion = this.items.permisos.originales[i].descripcion;
								this.items.permisos.originales[i].aplicacion_nombre = this.items.permisos.originales[i].aplicacion;
							}

							this.items.permisos.seleccionados = Array.from(this.items.permisos.originales);
						}else {
							this.items.roles.originales = (await api.listar_rol_roles(this.rol.id)).data.roles_hijos;
							this.items.roles.seleccionados = Array.from(this.items.roles.originales);
						}
						this.modal = 2;
					}else
						this.$helper.showMessage('Error','Tienes que seleccionar el rol a editar','error','alert');
				}
			}
			,activo_inactivo: function(val) {
				return val == 1 ? 'Sí' : 'No';
			}
			,limpiar_rol_items: function() {
				this.items = {
					permisos: {
						originales: []
						,seleccionados: []
					}
					,roles: {
						originales: []
						,seleccionados: []
					}
				}
			}
			,guardar_modal: async function() {
				let rol;
				if (this.modal == 1) {
					if (this.rol.tipo == 2 && this.roles_nuevos.length > 0)
						this.rol.roles = this.roles_nuevos

					rol = (await api.crear_roles(this.rol)).data;
				}else {
					let tmpRol = Object.assign({}, this.rol);
					delete tmpRol.tipo;
					rol = (await api.editar_roles(tmpRol.id, tmpRol)).data;
				}

				if (this.rol.tipo == 1) {
					this.$log.info('nuevos', this.permisos_nuevos);
					this.$log.info('borrados', this.permisos_borrados);

					if (this.permisos_nuevos.length > 0)
						for(let i=0; i<this.permisos_nuevos.length; i++) {
							await api.asociar_permiso(rol.id, this.permisos_nuevos[i]);
						}

					if (this.permisos_borrados.length > 0) {
						for(let i=0; i<this.permisos_borrados.length; i++) {
							await api.desasociar_permiso(rol.id, this.permisos_borrados[i].id);
						}
					}
				}else {
					if (this.roles_nuevos.length > 0) {
						await api.asociar_rol(rol.id, {roles: this.roles_nuevos});
					}

					if (this.roles_borrados.length > 0) {
						await api.desasociar_rol(rol.id, {roles: this.roles_borrados});
					}
				}

				this.cancelar_modal();
				this.obtener_roles();
			}

			//Agregar permiso
			,obtener_aplicaciones: async function() {
				if (this.aplicaciones.length == 0)
					this.aplicaciones = (await api.listar_aplicaciones()).data;

				this.nuevoPermiso.aplicacion_id = this.aplicaciones[0].id;

				this.obtener_permisos();
			}
			,obtener_permisos: async function() {
				this.permisos = (await api.listar_app_permisos(this.nuevoPermiso.aplicacion_id)).data;
				this.nuevoPermiso.permiso_id = this.permisos[0].id;
			}
			,cancelar_permiso: function() {
				this.modalPermiso = false;
				this.nuevoPermiso = {
					aplicacion_id: 0
					,permiso_id: 0
					,alcance: 1
					,acceso: 1
					,ver_aplicacion: 1
					,aplicacion_nombre: null
					,permiso_descripcion: null
				}
			}
			,agregar_nuevo_permiso: async function() {
				await this.obtener_aplicaciones();
				this.modalPermiso = true;
			}
			,guardar_permiso: function() {
				let objApp = document.getElementById('aplicaciones');
				this.nuevoPermiso.aplicacion_nombre = objApp.options[objApp.selectedIndex].text;

				let objPer = document.getElementById('permisos');
				this.nuevoPermiso.permiso_descripcion = objPer.options[objPer.selectedIndex].text;

				this.items.permisos.seleccionados.push(this.nuevoPermiso);
				this.cancelar_permiso();
			}
			,quitar_permiso: function(id) {
				let permisos = [];
				let permisosSel = this.items.permisos.seleccionados;

				for(let i=0; i<permisosSel.length; i++) {
					if (permisosSel[i].permiso_id != id)
						permisos.push(permisosSel[i]);
				}

				this.items.permisos.seleccionados = permisos;
			}

			//Agregar rol
			,cancelar_rol: function() {
				this.modalRol = false;
				this.nuevoRol = {
					id: null
					,nombre: null
				};
			}
			,agregar_rol: function() {
				this.modalRol = true;
				this.nuevoRol.id = this.roles_validos[0].id;
			}
			,guardar_rol: function() {
				let objApp = document.getElementById('rol');
				this.nuevoRol.nombre = objApp.options[objApp.selectedIndex].text;

				this.items.roles.seleccionados.push(this.nuevoRol);
				this.cancelar_rol();
			}
			,quitar_rol: function(id) {
				let roles = [];
				let rolesSel = this.items.roles.seleccionados;

				for(let i=0; i<rolesSel.length; i++) {
					if (rolesSel[i].id != id)
						roles.push(rolesSel[i]);
				}

				this.items.roles.seleccionados = roles;
			}
		}
		,computed: {
			permisos_validos: function() {
				let permisos = [];
				let permisosSeleccionados = this.items.permisos.seleccionados;

				for(let i=0; i<this.permisos.length; i++) {
					let encontrado = false;
					for(let a=0; a<permisosSeleccionados.length; a++) {
						if (this.permisos[i].id == permisosSeleccionados[a].permiso_id) {
							encontrado = true;
							a = permisosSeleccionados.length;
						}
					}

					if (!encontrado)
						permisos.push(this.permisos[i]);
				}

				return permisos;
			}
			,permisos_nuevos: function() {
				let permisos = [];

				let permisosOrg = this.items.permisos.originales;
				let permisosSel = this.items.permisos.seleccionados;

				for(let i=0; i<permisosSel.length; i++) {
					let encontrado = false;

					for (let a=0; a<permisosOrg.length; a++) {
						if (permisosSel[i].permiso_id == permisosOrg[a].permiso_id) {
							encontrado = true;
							a = permisosOrg.length;
						}
					}

					if (!encontrado)
						permisos.push(permisosSel[i]);
				}

				return permisos;
			}
			,permisos_borrados: function() {

				let permisos = [];

				let permisosOrg = this.items.permisos.originales;
				let permisosSel = this.items.permisos.seleccionados;

				for(let i=0; i<permisosOrg.length; i++) {
					let encontrado = false;

					for (let a=0; a<permisosSel.length; a++) {
						if (permisosOrg[i].permiso_id == permisosSel[a].permiso_id) {
							encontrado = true;
							a = permisosSel.length;
						}
					}

					if (!encontrado)
						permisos.push(permisosOrg[i]);
				}

				return permisos;
			}

			//roles
			,roles_validos: function() {
				let roles = [];
				let rolesSel = this.items.roles.seleccionados;

				for(let i=0; i<this.roles.length; i++) {
					let encontrado = false;

					for(let a=0; a<rolesSel.length; a++) {
						if (this.roles[i].id == rolesSel[a].id) {
							encontrado = true;
							a=rolesSel.length;
						}
					}

					if (this.rol.id && this.roles[i].id == this.rol.id)
						encontrado = true;

					if (this.roles[i].tipo != 1)
						encontrado = true;

					if (!encontrado)
						roles.push(this.roles[i]);
				}

				return roles;
			}
			,roles_nuevos: function() {
				let roles = [];

				let rolesOrg = this.items.roles.originales;
				let rolesSel = this.items.roles.seleccionados;

				for(let i=0; i<rolesSel.length; i++) {
					let encontrado = false;

					for(let a=0; a<rolesOrg.length; a++) {
						if (rolesSel[i].id == rolesOrg[a].id) {
							encontrado = true;
							a = rolesOrg.length;
						}
					}

					if (!encontrado)
						roles.push(rolesSel[i].id);
				}

				return roles;
			}
			,roles_borrados: function() {
				let roles = [];

				let rolesOrg = this.items.roles.originales;
				let rolesSel = this.items.roles.seleccionados;

				for(let i=0; i<rolesOrg.length; i++) {
					let encontrado = false;

					for(let a=0; a<rolesSel.length; a++) {
						if (rolesOrg[i].id == rolesSel[a].id) {
							encontrado = true;
							a = rolesSel.length;
						}
					}

					if (!encontrado)
						roles.push(rolesOrg[i].id);
				}

				return roles;
			}
		}
	}
</script>

<style lang="scss">
	.list-header {
		background-color: #0781cd;
		color: #fff;
	}
	.list-item {
		.quit {
			font-size: bold;
			cursor: pointer;
		}

		img {
			width: 15px;
		}
	}
	.list-item:nth-child(2n+1) {
		background-color: #BFDCEE;
	}
</style>